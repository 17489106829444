<template>
    <SfSection
        v-if="leadText"
        class="article"
    >
        <div v-html="leadText" class="lead" />  <!-- eslint-disable-line -->
    </SfSection>
</template>

<script>
import { get } from 'lodash-es';
import contentBlockMixin from '~/mixins/content-block';
import SfSection from '~/patterns/organisms/section/section';

export default {
    components: {
        SfSection,
    },

    mixins: [
        contentBlockMixin
    ],

    computed: {
        leadText() {
            return get(this, 'block.wysiwyg', '');
        }
    }
};
</script>
