<template>
    <section class="sf-accordion">
        <DnAccordion
            :items="items"
            :scroll-offset="200"
        >
            <template #title="{ item }">
                <component
                    :is="headingTag"
                    class="sf-accordion__heading-tag"
                >
                    {{ item.title }}
                </component>
            </template>

            <template #default="{ item }">
                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="accordion__content"
                    v-html="item.content"
                />
            </template>

            <template #icon>
                <AscIcon
                    icon="chevron-down"
                    class="accordion__icon"
                />
            </template>
        </DnAccordion>

        <div
            v-if="!!$slots.footer"
            class="accordion__footer"
        >
            <slot name="footer" />
        </div>
    </section>
</template>

<script>
import DnAccordion from '@digitalnatives/accordion';
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        DnAccordion,
        AscIcon
    },

    props: {
        openIndexes: {
            type: Array,
            required: false,
            default: () => []
        },

        items: {
            type: Array,
            required: true,
            default: () => []
        },

        headingTag: {
            type: String,
            required: true,
            default: 'div',
            validator: value => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'].includes(value)
        }
    },

    data() {
        return {
            visibleRowIndexes: [...this.openIndexes]
        };
    },

    methods: {
        toggleRow(index) {
            const arrayIndex = this.visibleRowIndexes.indexOf(index);

            if (arrayIndex === -1) {
                this.visibleRowIndexes.push(index);
            } else {
                this.visibleRowIndexes.splice(arrayIndex, 1);
            }
        }
    }
};
</script>

<style lang="less" src="./accordion.less"></style>
