<template>
    <!-- eslint-disable vue-no-v-html -->
    <SfSection v-if="block && block.accordion && block.accordion.length">
        <template
            v-if="block.title"
            #title
        >
            {{ block.title }}
        </template>

        <template #default>
            <SfContentContainer>
                <SfAccordion
                    :items="block.accordion"
                    :heading-tag="block.title ? 'h3' : 'h2'"
                >
                    <template #default="{ item }">
                        <!-- eslint-disable vue/no-v-html -->
                        <div
                            class="article"
                            v-html="item.content"
                        />
                        <!-- eslint-enable vue/no-v-html -->
                    </template>

                    <template #footer>
                        <NuxtLink
                            v-if="link && link.url"
                            :to="link.url"
                        >
                            {{ link.title }}
                        </NuxtLink>
                    </template>
                </SfAccordion>
            </SfContentContainer>
        </template>
    </SfSection>
</template>

<script>
import contentBlockMixin from '~/mixins/content-block';
import SfSection from '~/patterns/organisms/section/section';
import SfContentContainer from '~/patterns/organisms/content-container/content-container';
import SfAccordion from '~/patterns/molecules/accordion/accordion.vue';

export default {
    components: {
        SfSection,
        SfContentContainer,
        SfAccordion
    },

    mixins: [
        contentBlockMixin
    ],

    props: {
        link: {
            type: Object,
            default: null
        },
    }
};
</script>
